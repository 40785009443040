.new-quote {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.4rem;
  outline: none;
  padding: 1.2rem 1.5rem;
  transition: opacity 0.2s;

  @media screen and (max-width: $mediumMobile) {
    font-size: 1.2rem;
    padding: 1.5rem;
  }
}