@import url(https://fonts.googleapis.com/css?family=Raleway:600|Roboto:300&display=swap);
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  box-sizing: border-box; }

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem; }

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center; }

#text {
  font-family: 'Raleway', sans-serif;
  font-size: 2.8rem;
  margin: 2rem 3rem; }
  @media screen and (max-width: 550px) {
    #text {
      font-size: 1.8rem; } }

#author {
  margin: 0 5rem 2.5rem 0;
  text-align: right; }
  @media screen and (max-width: 450px) {
    #author {
      font-size: 1.5rem; } }
  @media screen and (max-width: 375px) {
    #author {
      font-size: 1.3rem; } }

#tweet-quote {
  color: #ffffff;
  font-size: 2rem;
  padding-top: 0.6rem; }

.tweet-quote-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.new-quote {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.4rem;
  outline: none;
  padding: 1.2rem 1.5rem;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
  @media screen and (max-width: 450px) {
    .new-quote {
      font-size: 1.2rem;
      padding: 1.5rem; } }

#quote-box {
  background-color: #ffffff;
  border-radius: 5px;
  margin: auto;
  padding: 5rem;
  width: 55rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around; }
  @media screen and (max-width: 768px) {
    #quote-box {
      padding: 5rem 4rem;
      width: 45rem; } }
  @media screen and (max-width: 550px) {
    #quote-box {
      padding: 3rem 0rem;
      width: 35rem; } }
  @media screen and (max-width: 450px) {
    #quote-box {
      width: 30rem; } }
  @media screen and (max-width: 375px) {
    #quote-box {
      width: 28rem; } }

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem; }

#copyright {
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.2rem;
  padding-bottom: 4rem; }

.copyright__link {
  border-bottom: 1px dotted #ffffff;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s; }
  .copyright__link:hover {
    opacity: 0.5; }

