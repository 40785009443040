#tweet-quote {
  color: #ffffff;
  font-size: 2rem;
  padding-top: 0.6rem;
}

.tweet-quote-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  transition: opacity 0.2s;
}